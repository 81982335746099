import { sentbizApi } from '../axios'

export interface IDeleteRecipient {
  request (id: number): Promise<any>
}

export class DeleteRecipient implements IDeleteRecipient {
  async request (id: number): Promise<any> {
    const option: RequestOption = {
      url: 'remittance/recipients',
      method: 'delete',
      data: { id }
    }
    const response: any = await sentbizApi.request(option)
    return response
  }
}
