
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import SimpleDataTable from '@/components/shared/SimpleDataTable.vue'
import { ReceiveMethodEnum } from '@/enums'
import CountryEnum from '@/enums/CountryEnum'
import FormRule from '@/models/forms/FormRule'
import { regexToPatternString } from '@/data/Regex'
import MemberInfo from '@/models/MemberInfo'
import { GetRecipients, GetRecipientsParam } from '@/gateway/recipient/GetRecipients'
import { staticValues } from '@/static/StaticValues'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import { DeleteRecipient, IDeleteRecipient } from '@/gateway/recipient/DeleteRecipient'
import { IdCardTypeEnum } from '@/enums/IdCardTypeEnum'

interface ListCountSelectorTypes {
  TEN: number,
  THIRTY: number,
  FIFTY: number
}

@Component({
  components: { SimpleDataTable }
})
export default class RecipientList extends Vue {
  @Prop({ type: String, default: '' }) readonly title!: string
  @Prop({ type: Boolean, default: false }) readonly useSelection!: boolean
  @Prop({ type: Boolean, default: false }) readonly hasNextButton!: boolean
  @Prop({ type: Number, default: 10 }) readonly unit!: number
  @Prop({ type: Array, default: () => [] }) readonly includeColumn!: Array<string>
  @Prop({ type: Array, default: () => [] }) readonly excludeColumn!: Array<string>
  @Prop({ type: Array, default: false }) readonly isHeaderFontSizeLarge!: boolean

  private deleteRecipientGateway: IDeleteRecipient = new DeleteRecipient()

  private corpPids = 'corp_pids'

  public LIST_COUNT_SELECTOR_TYPES: ListCountSelectorTypes = {
    TEN: 10,
    THIRTY: 30,
    FIFTY: 50
  }

  recipients: Dictionary<Array<Dictionary<any>>> = {}
  hideColumnProps: Array<string> = ['first_name', 'middle_name', 'last_name', 'master_code']
  page: number = 1
  totalCount: number = 0
  hasRecipients: boolean = false
  selectRecipientsCorpPid: Set<string> = new Set()
  selectRecipientsWithCountry: Dictionary<Array<Dictionary<any>>> = {}
  countryFilter: string = ''
  countries: Array<{ label: string; value: string }> = []
  searchType: string = this.corpPids
  searchOptions: Array<{ label: string; value: string }> = []
  searchFormField: { search: string } = { search: '' }
  selectedListCount: number = this.unit;
  corpPidsRule: Dictionary<Array<FormRule>> = {
    search: [{ trigger: 'change', message: '', pattern: regexToPatternString('corps_id') }]
  }
  nameRule: Dictionary<Array<FormRule>> = {
    search: [{ trigger: 'change', message: '', pattern: regexToPatternString('english') }]
  }
  dataTableRerenderKey: number = 0

  fixedColumns: Array<string> = ['remove_btn']

  get getRule(): Dictionary<Array<FormRule>> {
    if (this.searchType === this.corpPids) return this.corpPidsRule
    return this.nameRule
  }

  get isEmptySelection(): boolean {
    return !this.selectRecipientsCorpPid.size
  }

  get getApplyButtonLabel(): string {
    return this.isEmptySelection
      ? `${this.$t('commons.select_recipient')}`
      : `${this.$t('commons.seleted_recipients_number', {
          total_recipients_number: this.selectRecipientsCorpPid.size
        })}`
  }

  get noDataLabel(): string {
    return this.searchFormField.search
      ? `${this.$t('commons.recipients_no_found_search')}`
      : `${this.$t('commons.recipients_no_found_register')}`
  }

  get noDataSubLabel(): string {
    return !this.searchFormField.search ? `${this.$t('commons.if_how_register_recipients_no')}` : ''
  }

  get manualDownloadLabel(): string {
    return !this.searchFormField.search ? `${this.$t('commons.manual_download')}` : ''
  }

  get usableExcelDownload() {
    return !this.useSelection
  }

  get selectedListCountModel () {
    return this.selectedListCount;
  }

  set selectedListCountModel (selectedValue: number) {
    this.selectedListCount = selectedValue;
    this.getRecipients();    
  }

  onSelectRecipient(selectRow: any): void {
    const countryWithMethod = `${selectRow.country.toString().toUpperCase()}_${selectRow.remittance_method_type}`
    const corpPid: string = selectRow.corp_pid
    if (this.selectRecipientsCorpPid.has(corpPid)) {
      this.selectRecipientsCorpPid.delete(corpPid)
      const deleteRecipientIndex = this.selectRecipientsWithCountry[countryWithMethod].findIndex(
        recipient => recipient.corp_pid === corpPid
      )
      this.selectRecipientsWithCountry[countryWithMethod].splice(deleteRecipientIndex, 1)
    } else {
      this.selectRecipientsCorpPid.add(corpPid)
      if (!this.selectRecipientsWithCountry[countryWithMethod]) this.selectRecipientsWithCountry[countryWithMethod] = []
      this.selectRecipientsWithCountry[countryWithMethod].push(selectRow)
    }
    this.selectRecipientsCorpPid = new Set(this.selectRecipientsCorpPid)
  }

  apply(): void {
    this.$emit('apply', this.selectRecipientsWithCountry)
  }

  async onChangeCountry(): Promise<void> {
    this.recipients = Object.assign({})
    this.page = 1
    await this.getRecipients()
  }

  async onChangePage(page: number): Promise<void> {
    if (this.recipients.hasOwnProperty(page)) return
    await this.getRecipients()
  }

  sortAscending() {
    this.countries = this.countries.sort((a, b) => {
      return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
    })
  }

  setCountries(): void {
    if (!Object.keys(this.recipients).length) return
    staticValues.availableCountriesIso.forEach(country => {
      const value: string = country
      const label = this.$t(`country.${[value.toLowerCase()]}`)
      this.countries.push({ label, value } as { label: string; value: string })
      this.sortAscending()
    })
  }

  setSearchOptions(): void {
    this.searchOptions.push(
      { label: `${this.$t('commons.recipient_number')}`, value: this.corpPids },
      { label: `${this.$t('commons.name')}`, value: 'recipient_name' }
    )
  }

  setRuleMessage(): void {
    this.corpPidsRule.search[0].message = `${this.$t('commons.type_number_english')}`
    this.nameRule.search[0].message = `${this.$t('commons.type_only_english')}`
  }

  async formValidate(): Promise<any> {
    try {
      return await (this.$refs.searchForm as Vue & { validate: () => boolean | any }).validate()
    } catch (error) {
      return error
    }
  }

  async submitSearch(): Promise<void> {
    const valid: boolean = await this.formValidate()
    if (!valid) return
    this.recipients = Object.assign({})
    this.page = 1
    await this.getRecipients()
  }

  filterColumns(recipient: Dictionary<any>): Dictionary<any> {
    const isIncludeColumn: boolean = !!this.includeColumn.length
    const isExcludeColumn: boolean = !!this.excludeColumn.length
    const filteredRecipient: Dictionary<any> = {}
    recipient.full_name = `${recipient.first_name} ${recipient.middle_name ?? ''} ${recipient.last_name ?? ''}`
    let columns: Array<string> = []
    if (isIncludeColumn) {
      columns = this.includeColumn
    } else if (isExcludeColumn) {
      columns = Object.keys(recipient).filter(column => {
        return !this.excludeColumn.includes(column)
      })
    }
    const cardNumber: string = 'debit_card_number'
    const wingMoneyNumber: string = 'payment_gateway_wing_money_account_number'
    const isShowCardNumber: boolean = recipient[cardNumber] || this.countryFilter === CountryEnum[CountryEnum.CN]
    const isShowWingMoneyNumber: boolean =
      recipient[wingMoneyNumber] || this.countryFilter === CountryEnum[CountryEnum.KH]
    if (isShowCardNumber) {
      columns = columns.filter(column => column !== 'bank_account_number')
      columns.push(cardNumber)
    } else if (isShowWingMoneyNumber) {
      columns = columns.filter(column => column !== 'bank_account_number')
      columns.push(wingMoneyNumber)
    } else {
      columns = columns.filter(column => ![cardNumber, wingMoneyNumber].includes(column))
    }
    columns.forEach(column => {
      if (recipient[column]) {
        if (column === 'first_name') filteredRecipient.full_name = recipient.full_name
        filteredRecipient[column] = recipient[column]
      }
    })
    return filteredRecipient
  }

  filterRecipients(recipient: Dictionary<any>): Dictionary<any> {
    const bankData = recipient.remittance_method_data
    recipient = Object.assign({}, recipient, bankData)
    delete recipient.remittance_method_data
    recipient.remittance_method_type = ReceiveMethodEnum[recipient.remittance_method_type]

    const idTypeData = recipient.id_document_type
    recipient.id_document_type = IdCardTypeEnum[idTypeData]
      ? this.$t(`sheet.id_types.${IdCardTypeEnum[idTypeData].toLowerCase()}`)
      : ''

    return recipient
  }

  getCorpPidsParam(): Array<string> | undefined {
    if (!this.searchFormField.search) return
    if (this.searchType === this.corpPids) {
      const pids: string = this.searchFormField.search.replace(/ /g, '').trim()
      return Array(pids)
    }
  }

  getRecipientNameParam(): string | undefined {
    if (!this.searchFormField.search) return
    if (this.searchType !== this.corpPids) {
      return this.searchFormField.search
    }
  }

  async getRecipients(): Promise<void> {
    if (!MemberInfoGateway.hasAuthInfo()) {
      await this.$router.push('/signIn')
      return
    }
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    const country = this.countryFilter
    const page: number = this.page
    const getRecipientsParam: GetRecipientsParam = {
      corp_id: memberInfo.corp_id,
      country,
      corp_pids: this.getCorpPidsParam(),
      recipient_name: this.getRecipientNameParam(),
      page,
      unit: this.selectedListCount
    }
    const getRecipientsResponse = await GetRecipients.getInstance().request(getRecipientsParam)
    const recipients = getRecipientsResponse.list
    if (!recipients?.length) return
    const filteredRecipients = recipients.map(recipient => {
      const filteredRecipient: Dictionary<any> = this.filterRecipients(recipient)
      filteredRecipient.remove_btn = {
        type: 'button',
        text: this.$t('commons.delete'),
        buttonType: 'danger',
        plain: true,
        callback: async () => {
          this.$confirm(`${this.$t('notification.delete_confirm')}`, {
            confirmButtonText: `${this.$t('commons.delete')}`,
            cancelButtonText: `${this.$t('commons.close')}`,
            type: 'warning'
          })
            .then(async () => {
              const response: any = await this.deleteRecipientGateway.request(Number(recipient.id))
              if (response.code !== 200) {
                if (response.code === 22) {
                  this.$message({ type: 'error', message: `${this.$t('notification.recipient_with_history')}` })
                  return
                }
                this.$message({ type: 'error', message: `${this.$t('notification.errors.error')}` })
                return
              }
              this.$message({ type: 'success', message: `${this.$t('commons.delete_done')}` })
              await this.getRecipients()
              this.dataTableRerenderKey++
            })
            .catch(() => {})
        }
      }
      const columnFiltered: Dictionary<any> = this.filterColumns(filteredRecipient)

      return columnFiltered
    })
    this.recipients[page] = Array(...filteredRecipients)
    this.recipients = Object.assign({}, this.recipients)
    this.totalCount = getRecipientsResponse.total_count
    this.hasRecipients = true
    this.$emit('totalCount', this.totalCount)
  }

  
  async created() {
    await this.getRecipients()
    this.setCountries()
    this.setSearchOptions()
    this.setRuleMessage()
  }
}
